import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Define the callback function in the global scope
window.initializeGoogleMaps = function() {
  console.log("Google Maps API loaded");
  const event = new Event('google-maps-loaded');
  window.dispatchEvent(event);
};

export default class extends Controller {
  static targets = ["input", "results", "normalResults", "form", "submit"];

  connect() {
    this.apiKey = document.querySelector("meta[name='google-autocomplete-api-key']").content;
    
    // Monitor changes on the hidden field to detect platform changes
    const platformField = document.querySelector('#selected-value[name="search[platform]"]');
    if (platformField) {
      platformField.addEventListener("change", this.toggleAutocomplete.bind(this));
    }

    window.addEventListener('google-maps-loaded', () => {
      this.toggleAutocomplete();
    });
  
    // Check the current value on page load
    this.initializeAutocomplete();
  }  
  
  toggleAutocomplete() {
    const platformValue = document.querySelector('#selected-value[name="search[platform]"]')?.value;
  
    if (platformValue === "1") {
      this.initializeAutocomplete();
      this.disableForm();
    } else {
      this.destroyAutocomplete();
      this.enableForm();
    }
  }
  
  initializeAutocomplete() {

    // Check if the Google Maps API is already loaded
    if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
      console.log("Google Maps SDK not loaded. Loading it now...");

      // Dynamically load the Google Maps script if not already loaded
      if (!window.googleMapsLoaded) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places&callback=initializeGoogleMaps`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
        window.googleMapsLoaded = true;
      }
      return;
    }
    
    console.log("Binding autocomplete to input field");

    this.normalResultsTarget.classList.add("hidden")
  
    if (!this.autocomplete) {
      this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, {
        types: ['establishment']        
      });
  
      this.autocomplete.addListener("place_changed", this.handlePlaceSelect.bind(this));

    }
  }
  
  destroyAutocomplete() {
    if (this.hasNormalResultsTarget && this.normalResultsTarget.querySelectorAll("li").count > 0){
      this.normalResultsTarget.classList.remove("hidden")
    }
    if (this.autocomplete) {
      // Remove the event listener and clear the instance
      google.maps.event.clearInstanceListeners(this.inputTarget);
      this.autocomplete = null;
    }
  
    // Clear any existing results
    this.resultsTarget.textContent = "";
  }

  async handlePlaceSelect() {
    const place = this.autocomplete.getPlace();
    console.log(place);

    const search_result = await this.createSearchResult({
      search_id: null,
      platform: "Google Maps",
      score: 1,
      product_name: place["name"],
      url: place["url"],
      external_id: place["place_id"],
      review_count: place["rating"],
      review_rating: place["user_ratings_total"],
    });

    let source = document.querySelector("#search_source").value

    console.log(search_result);

    if (source == "app"){
      window.location = `/new-review-profile?search_result_id=${search_result.id}`
      //Create review profile from search result
    }
    else if (source == "export"){
      const new_export = await this.createExport(search_result);
      window.location = `/exports/${new_export.id}`
      //Create export from search result
    }
    else if (source == "links"){
      const new_link = await this.createLink(search_result);
      console.log(new_link);
      window.location = `/links/${new_link.id}`
      //Create link from search result
    }
  }  

  async createSearchResult(data) {
    try {
      const response = await post("/search_results", {
        body: JSON.stringify({ search_result: data }),
        contentType: "application/json",
        responseKind: "json",
      });
  
      if (response.ok) {
        const result = await response.json;
        console.log("Search result response JSON:", result);
        return result;
      } else {
        console.error("Error creating search result", await response.text());
      }
    } catch (error) {
      console.error("Request failed", error);
    }
  }

  async createExport(data) {
    try {
      const response = await post("/exports", {
        body: JSON.stringify({ search_result_id: data.id }),
        contentType: "application/json",
        responseKind: "json",
      });
  
      if (response.ok) {
        const result = await response.json;
        console.log("Export JSON", result);
        return result;
      } else {
        console.error("Error creating search result", await response.text());
      }
    } catch (error) {
      console.error("Request failed", error);
    }
  }

  async createLink(search_result) {
    try {      
      const response = await post("/links", {        
        body: JSON.stringify({
          search_result_id: search_result.id
        }),
        contentType: "application/json",
        responseKind: "json",
      });
  
      if (response.ok) {
        const result = await response.json;
        console.log("Link JSON:", result);
        return result;
      } else {
        console.error("Error creating search result", await response.text());
      }
    } catch (error) {
      console.error("Request failed", error);
    }
  }

  disableForm() {
    this.formTarget.setAttribute("data-search-disabled", "true");
    this.submitTarget.disabled = true;
    this.formTarget.addEventListener("submit", this.preventFormSubmit);
    this.submitTarget.classList.add("hidden");
  }
  
  enableForm() {
    this.formTarget.removeAttribute("data-search-disabled");
    this.submitTarget.disabled = false;
    this.formTarget.removeEventListener("submit", this.preventFormSubmit);
    this.submitTarget.classList.remove("hidden");
  }
  
  preventFormSubmit(event) {
    event.preventDefault();
  }

}