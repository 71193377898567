import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  connect() {
    console.log("Connected")
    this.element.addEventListener('click', this.handleClick.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('click', this.handleClick.bind(this))
  }

  handleClick(event) {
    console.log("Click!")
    if (event.target.tagName === 'A' && this.isInsideTurboFrame(event.target)) {
      console.log("Inside Turbo Frame")
      event.preventDefault()
      this.makeTurboStreamRequest(event.target.href)
    }
  }

  isInsideTurboFrame(element) {
    while (element) {
      if (element.tagName === 'TURBO-FRAME' && element.id === 'pagy-products') {
        return true
      }
      element = element.parentElement
    }
    return false
  }

  makeTurboStreamRequest(url) {
    history.pushState({}, '', url);
    get(url, {
      responseKind: "turbo-stream"
    }).then(html => {
      let parser = new DOMParser()
      let doc = parser.parseFromString(html, 'text/html')
      let turboFrame = doc.querySelector('turbo-frame[id="pagy-products"]')
      if (turboFrame) {
        document.querySelector('turbo-frame[id="pagy-products"]').innerHTML = turboFrame.innerHTML
      }
    })
  }

  show (event) {
    event.preventDefault();    
    const productId = event.currentTarget.dataset.id;
    window.location = '/g2_products/' + productId;
    history.pushState({}, '', `/g2_products/${productId}`);
    get(`/g2_products/${productId}`, {
      responseKind: "turbo-stream"
    });
    document.querySelector("#search_results_frame").querySelectorAll("div").forEach((item) => {item.remove();});
  }

  /**
   * Handles the change of week.
   * @param {Event} event - The event object triggered by the change of week.
   */  
  change_week(event) {    
    event.preventDefault();
    
    // Get the ref_date value from the event target's data-ref-date attribute
    const refDateStr = event.currentTarget.dataset.refDate;
    
    // Parse the ref_date value as a Date object
    let refDate = new Date(refDateStr);    
    
    // Get the direction from the data-direction attribute
    const direction = event.currentTarget.dataset.direction;
    console.log(direction);
    console.log(refDate);
    
    // Adjust the date based on the direction
    if (direction === "prev") {
      refDate.setDate(refDate.getDate() - 7);
    } else if (direction === "next") {
      refDate.setDate(refDate.getDate() + 7);
    }
    
    console.log(refDate);
    
    // Format the new date back to a string in the same format as refDate (assuming YYYY-MM-DD)
    const newRefDate = refDate.toISOString().split('T')[0];
    console.log(newRefDate);
    
    // Get the current URL
    const currentUrl = new URL(window.location.href);
    
    // Set or update the ref_date parameter in the URL
    currentUrl.searchParams.set('ref_date', newRefDate);
  
    // Update the browser's address bar without reloading the page
  //history.pushState({}, '', currentUrl.toString());
  
  
  // Make the Turbo Stream request to update the content
  this.makeTurboStreamRequest(currentUrl.toString());
  }

  handleCategoryClick(event) {
    event.preventDefault();
    // Get the destination URL from the link
    const destinationUrl = new URL(event.target.href, window.location.origin);
    
    // Get the current URL
    const currentUrl = new URL(window.location.href);
    
    // Merge the parameters from the destination URL into the current URL
    destinationUrl.searchParams.forEach((value, key) => {
      currentUrl.searchParams.set(key, value);
    });
    this.makeTurboStreamRequest(currentUrl.toString());
  }
}